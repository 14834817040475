var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c(
              "b-card",
              { staticClass: "mb-1", attrs: { header: "Informações básicas" } },
              [
                _c("b-card-text", [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Nome completo "),
                          ]),
                          _c("b-form-input", {
                            class: {
                              "is-invalid": _vm.$v.item.full_name.$error,
                            },
                            model: {
                              value: _vm.item.full_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "full_name", $$v)
                              },
                              expression: "item.full_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" CPF "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["###.###.###-##"],
                              expression: "['###.###.###-##']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.cpf,
                              expression: "item.cpf",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.item.cpf.$error },
                          attrs: {
                            type: "tel",
                            name: "cpf",
                            placeholder: "000.000.000-00",
                          },
                          domProps: { value: _vm.item.cpf },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "cpf", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group mb-md-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Celular "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) #####-####", "(##) ####-####"],
                              expression:
                                "['(##) #####-####', '(##) ####-####']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.cellphone,
                              expression: "item.cellphone",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.item.cellphone.$error },
                          attrs: {
                            type: "tel",
                            name: "cpf",
                            placeholder: "(00) 00000-0000",
                          },
                          domProps: { value: _vm.item.cellphone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "cellphone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" E-mail "),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.email,
                              expression: "item.email",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.item.email.$error },
                          attrs: { type: "email" },
                          domProps: { value: _vm.item.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "email", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Acesso CRM "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.item.crm.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: [
                                { code: 1, title: "Sim" },
                                { code: 0, title: "Não" },
                              ],
                              searchable: false,
                            },
                            model: {
                              value: _vm.item.crm,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "crm", $$v)
                              },
                              expression: "item.crm",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Status "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-0" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.item.active.$error },
                            attrs: {
                              searchable: false,
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: [
                                { title: "Ativo", code: 1 },
                                { title: "Bloqueado", code: 0 },
                              ],
                            },
                            model: {
                              value: _vm.item.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "active", $$v)
                              },
                              expression: "item.active",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _vm._v(" Senha "),
                        _c(
                          "small",
                          { staticStyle: { "font-size": "0.9rem" } },
                          [_vm._v("(6 à 10 caractéres)")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-merge" },
                            [
                              _vm.passwordFieldType === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password,
                                        expression: "item.password",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.item.password)
                                        ? _vm._i(_vm.item.password, null) > -1
                                        : _vm.item.password,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.item.password,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "password",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "password",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.item, "password", $$c)
                                        }
                                      },
                                    },
                                  })
                                : _vm.passwordFieldType === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password,
                                        expression: "item.password",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: { type: "radio" },
                                    domProps: {
                                      checked: _vm._q(_vm.item.password, null),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.item,
                                          "password",
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password,
                                        expression: "item.password",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: { type: _vm.passwordFieldType },
                                    domProps: { value: _vm.item.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _c(
                                "b-input-group-append",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: _vm.passwordToggleIcon },
                                    on: { click: _vm.togglePasswordVisibility },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [_vm._v(" Confirmação de senha ")]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mb-md-0" },
                            [
                              _vm.passwordFieldType === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password_confirmation,
                                        expression:
                                          "item.password_confirmation",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: {
                                      id: "confirmation_senha",
                                      name: "senha2",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.item.password_confirmation
                                      )
                                        ? _vm._i(
                                            _vm.item.password_confirmation,
                                            null
                                          ) > -1
                                        : _vm.item.password_confirmation,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.item.password_confirmation,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.item,
                                                "password_confirmation",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.item,
                                                "password_confirmation",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.item,
                                            "password_confirmation",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  })
                                : _vm.passwordFieldType === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password_confirmation,
                                        expression:
                                          "item.password_confirmation",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: {
                                      id: "confirmation_senha",
                                      name: "senha2",
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.item.password_confirmation,
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.item,
                                          "password_confirmation",
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.item.password_confirmation,
                                        expression:
                                          "item.password_confirmation",
                                      },
                                    ],
                                    staticClass:
                                      "form-control-merge form-control",
                                    attrs: {
                                      id: "confirmation_senha",
                                      name: "senha2",
                                      type: _vm.passwordFieldType,
                                    },
                                    domProps: {
                                      value: _vm.item.password_confirmation,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.item,
                                          "password_confirmation",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                              _c(
                                "b-input-group-append",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: _vm.passwordToggleIcon },
                                    on: { click: _vm.togglePasswordVisibility },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c("b-card", { staticClass: "mb-1" }, [
              _c("div", { staticClass: "form-row" }, [
                _vm.$can("Permissão", "Sistema")
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Permissão "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid": _vm.$v.item.cellphone.$error,
                            },
                            attrs: {
                              searchable: false,
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsPermissions,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.permissionSelected(
                                  _vm.item.permission
                                )
                              },
                            },
                            model: {
                              value: _vm.item.permission,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "permission", $$v)
                              },
                              expression: "item.permission",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", [_vm._v(" Unidade")]),
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsUnits,
                          searchable: false,
                        },
                        model: {
                          value: _vm.item.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "unit", $$v)
                          },
                          expression: "item.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c(
              "b-card",
              { staticClass: "mb-1" },
              [
                _c(
                  "b-card-text",
                  [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Produtos - Categorias "),
                    ]),
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        " Você pode determinar o percentual de desconto que o usuário poderá conceder ao aluno. "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-row d-flex-left mb-1",
                        staticStyle: { "border-bottom": "1px solid #f2f2f2" },
                      },
                      [
                        _c("div", { staticClass: "col-md-9 pb-0 pb-md-50" }, [
                          _vm._v("Categoria"),
                        ]),
                        _c("div", { staticClass: "col-md-3 pb-0 pb-md-50" }, [
                          _vm._v("Máx. %"),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.item.categories, function (el, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "form-group",
                          class:
                            _vm.item.categories.length - 1 === index
                              ? "mb-0"
                              : "",
                        },
                        [
                          _c("div", { staticClass: "form-row d-flex-left" }, [
                            _c("div", { staticClass: "col-md-9" }, [
                              _vm._v(" " + _vm._s(el.title) + " "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("money", {
                                  staticClass: "form-control",
                                  attrs: { placeholder: "0.00" },
                                  model: {
                                    value: el.max,
                                    callback: function ($$v) {
                                      _vm.$set(el, "max", $$v)
                                    },
                                    expression: "el.max",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm.$can("Permissão", "Sistema")
              ? _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: {
                      header: "Permissões",
                      "no-body": "",
                      "header-class": "mb-0",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center mx-2" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "w-100",
                            attrs: { variant: "primary" },
                            on: { click: _vm.selectAllPermissions },
                          },
                          [_vm._v(" Selecionar Tudo ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "table-container" },
                      [
                        _c("b-table", {
                          staticClass: "mb-0 text-no-wrap table-permissions",
                          attrs: {
                            striped: "",
                            responsive: "",
                            items: _vm.permissions,
                            fields: _vm.tableColumns,
                            "fixed-header": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(subject)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("b-form-checkbox", {
                                          staticClass: "mr-2",
                                          on: {
                                            change: function ($event) {
                                              return _vm.toggleSelectAllBySubject(
                                                data.item.subject,
                                                data.item.allSelected
                                              )
                                            },
                                          },
                                          model: {
                                            value: data.item.allSelected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                data.item,
                                                "allSelected",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.allSelected",
                                          },
                                        }),
                                        _c("strong", [
                                          _vm._v(_vm._s(data.item.subject)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(action)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "list-container" },
                                      _vm._l(
                                        data.item.actions,
                                        function (action, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "list-item",
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass:
                                                    "checkbox-permission",
                                                  attrs: {
                                                    name:
                                                      data.item.subject +
                                                      "_" +
                                                      action.value,
                                                    inline: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkboxChanged(
                                                        data.item.subject,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: action.checked,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        action,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "action.checked",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(action.value) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3297721864
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "form-row justify-content-end modal-footer-custom" },
        [
          _c(
            "div",
            { staticClass: "col-md-3 col-12" },
            [
              _c("ButtonsActionsFooter", {
                attrs: {
                  routerBack: "config-users-list",
                  variant: "success",
                  submited: _vm.submited,
                  text: "Salvar",
                  subtext: "Aguarde...",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }