<template>
  <b-form @submit.prevent="submitUpdate" autocomplete="off">
    <div class="form-row">
      <div class="col-md-6">
        <b-card header="Informações básicas" class="mb-1">
          <b-card-text>
            <div class="form-row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Nome completo
                  </label>
                  <b-form-input
                    v-model="item.full_name"
                    :class="{ 'is-invalid': $v.item.full_name.$error }"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    CPF
                  </label>
                  <input
                    v-mask="['###.###.###-##']"
                    type="tel"
                    v-model="item.cpf"
                    :class="{ 'is-invalid': $v.item.cpf.$error }"
                    name="cpf"
                    placeholder="000.000.000-00"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group mb-md-0">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Celular
                  </label>
                  <input
                    v-mask="['(##) #####-####', '(##) ####-####']"
                    type="tel"
                    v-model="item.cellphone"
                    :class="{ 'is-invalid': $v.item.cellphone.$error }"
                    name="cpf"
                    placeholder="(00) 00000-0000"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    E-mail
                  </label>
                  <input
                    type="email"
                    v-model="item.email"
                    :class="{ 'is-invalid': $v.item.email.$error }"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <label for=""> Acesso CRM </label>
                <div class="form-group">
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="item.crm"
                    :options="[
                      { code: 1, title: 'Sim' },
                      { code: 0, title: 'Não' },
                    ]"
                    :searchable="false"
                    :class="{ 'is-invalid': $v.item.crm.$error }"
                  >
                  </v-select>
                </div>
              </div>

              <div class="col-md-6">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <div class="form-group mb-0">
                  <v-select
                    :searchable="false"
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="item.active"
                    :options="[
                      { title: 'Ativo', code: 1 },
                      { title: 'Bloqueado', code: 0 },
                    ]"
                    :class="{ 'is-invalid': $v.item.active.$error }"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-row">
              <div class="col-md-6">
                <label>
                  Senha
                  <small style="font-size: 0.9rem">(6 à 10 caractéres)</small>
                </label>
                <div class="form-group mb-md-0">
                  <b-input-group class="input-group-merge">
                    <input
                      v-model="item.password"
                      class="form-control-merge form-control"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <div class="col-md-6">
                <label> Confirmação de senha </label>
                <div class="form-group">
                  <b-input-group class="mb-md-0">
                    <input
                      id="confirmation_senha"
                      v-model="item.password_confirmation"
                      class="form-control-merge form-control"
                      :type="passwordFieldType"
                      name="senha2"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>

        <b-card class="mb-1">
          <div class="form-row">
            <div v-if="$can('Permissão', 'Sistema')" class="col-md-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Permissão
              </label>
              <div class="form-group mb-md-0">
                <v-select
                  :searchable="false"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.permission"
                  :options="optionsPermissions"
                  @input="permissionSelected(item.permission)"
                  :class="{ 'is-invalid': $v.item.cellphone.$error }"
                >
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <label> Unidade</label>
              <div class="form-group mb-md-0">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.unit"
                  :options="optionsUnits"
                  :searchable="false"
                >
                </v-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card class="mb-1">
          <b-card-text>
            <label>
              <i class="text-danger bi bi-record-circle"></i>
              Produtos - Categorias
            </label>
            <p class="mb-2">
              Você pode determinar o percentual de desconto que o usuário poderá
              conceder ao aluno.
            </p>
            <div
              class="form-row d-flex-left mb-1"
              style="border-bottom: 1px solid #f2f2f2"
            >
              <div class="col-md-9 pb-0 pb-md-50">Categoria</div>
              <div class="col-md-3 pb-0 pb-md-50">Máx. %</div>
            </div>

            <div
              :class="item.categories.length - 1 === index ? 'mb-0' : ''"
              class="form-group"
              v-for="(el, index) in item.categories"
              :key="index"
            >
              <div class="form-row d-flex-left">
                <div class="col-md-9">
                  {{ el.title }}
                </div>
                <div class="col-md-3">
                  <money
                    class="form-control"
                    placeholder="0.00"
                    v-model="el.max"
                  ></money>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>

      <div class="col-md-12">
        <b-card
          class="mb-1"
          header="Permissões"
          v-if="$can('Permissão', 'Sistema')"
          no-body
          header-class="mb-0"
        >
          <div class="d-flex justify-content-center mx-2">
            <b-button
              variant="primary"
              @click="selectAllPermissions"
              class="w-100"
            >
              Selecionar Tudo
            </b-button>
          </div>

          <div class="table-container">
            <b-table
              striped
              responsive
              :items="permissions"
              class="mb-0 text-no-wrap table-permissions"
              :fields="tableColumns"
              fixed-header
            >
              <template #cell(subject)="data">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="data.item.allSelected"
                    @change="
                      toggleSelectAllBySubject(
                        data.item.subject,
                        data.item.allSelected
                      )
                    "
                    class="mr-2"
                  >
                  </b-form-checkbox>
                  <strong>{{ data.item.subject }}</strong>
                </div>
              </template>
              <template #cell(action)="data">
                <div class="list-container">
                  <div
                    class="list-item"
                    v-for="(action, index) in data.item.actions"
                    :key="index"
                  >
                    <b-form-checkbox
                      :name="`${data.item.subject}_${action.value}`"
                      inline
                      class="checkbox-permission"
                      v-model="action.checked"
                      @change="checkboxChanged(data.item.subject, index)"
                    >
                      {{ action.value }}
                    </b-form-checkbox>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </b-card>
      </div>
    </div>

    <div class="form-row justify-content-end modal-footer-custom">
      <div class="col-md-3 col-12">
        <ButtonsActionsFooter
          routerBack="config-users-list"
          variant="success"
          :submited="submited"
          text="Salvar"
          subtext="Aguarde..."
        />
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BTooltip,
  BTabs,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BLink,
  BAlert,
  BTable,
  BTab,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import draggable from "vuedraggable";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { permissionsDefault } from "@/auth/utils";
import { Money } from "v-money";

export default {
  components: {
    BCard,
    BTab,
    BInputGroupAppend,
    BInputGroup,
    draggable,
    Money,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    flatPickr,
    vSelect,
    BForm,
    BRow,
    BCol,
    BLink,
    BAlert,
    ButtonsActionsFooter,
    BTable,
    BTabs,
    BAvatar,
    BMedia,
    BFormCheckbox,
    BSpinner,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      optionsProductCategories: [],
      optionsUnits: [],
      optionsPermissions: [],
      notFound: false,
      uuid: null,
      submited: false,
      item: {
        full_name: "",
        cpf: "",
        cellphone: "",
        email: "",
        password: "",
        password_confirmation: "",
        permissions: [],
        unit: "",
        permission: "",
        categories: [],
      },
      tableColumns: [
        {
          key: "subject",
          label: "",
          thStyle: "width: 200px",
        },
        {
          key: "action",
          label: "",
          class: "text-elipse-400",
        },
      ],
      permissions: permissionsDefault(),
      money: {
        decimal: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      user: "",
    };
  },
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
      },
      email: {
        required,
      },
      crm: {
        required,
      },
      cellphone: {
        required,
      },
      active: {
        required,
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.user = JSON.parse(localStorage.getItem("userData"));
  },
  async mounted() {
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    this.getData();
    this.getPermissionsSchool();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async getProductCategories() {
      this.$store.dispatch("Product/categories").then((res) => {
        this.item.categories = res.data;

        this.item.categories.map((element) => {
          element.max = 0;
          element.title = element.title;
        });
      });
    },
    permissionSelected(rows) {
      for (const defaultPermission of this.permissions) {
        const userPermission = rows.permissions.find(
          (userPerm) => userPerm.subject === defaultPermission.subject
        );
        if (userPermission) {
          for (const action of defaultPermission.actions) {
            const userAction = userPermission.actions.find(
              (userAct) => userAct.value === action.value
            );
            action.checked = userAction ? userAction.checked : false;
          }
        }

        this.checkIfAllSelectedBySubject(defaultPermission.subject);
      }
    },
    async getPermissionsSchool() {
      this.optionsPermissions = await this.$store.dispatch(
        "Permission/forSelect"
      );
    },
    selectAllPermissions() {
      this.permissions.forEach((permission) => {
        permission.actions.forEach((action) => {
          action.checked = true;
        });
        permission.allSelected = true;
      });
    },
    selectAllBySubject(subject) {
      const selectedSubject = this.permissions.find(
        (permission) => permission.subject === subject
      );
      if (selectedSubject) {
        selectedSubject.actions.forEach((action) => {
          action.checked = true;
        });
      }
    },
    checkboxChanged(subject, actionIndex) {
      const permissionItem = this.item.permissions.find(
        (item) => item.subject === subject
      );
      if (permissionItem) {
        permissionItem.actions[actionIndex].checked = true;
      }
    },
    toggleSelectAllBySubject(subject, isSelected) {
      const selectedSubject = this.permissions.find(
        (permission) => permission.subject === subject
      );
      if (selectedSubject) {
        selectedSubject.actions.forEach((action) => {
          action.checked = isSelected;
        });
      }
    },
    checkIfAllSelectedBySubject(subject) {
      const selectedSubject = this.permissions.find(
        (permission) => permission.subject === subject
      );
      if (selectedSubject) {
        if (selectedSubject.actions.every((action) => action.checked)) {
          selectedSubject.allSelected = true;
        } else {
          selectedSubject.allSelected = false;
        }
      }
    },
    async getData() {
      await this.$store
        .dispatch("User/show", this.$route.params.uuid)
        .then((res) => {
          this.item = res;

          if (!this.item.permission) {
            this.item.permissions = permissionsDefault();
          }

          for (const defaultPermission of this.permissions) {
            const userPermission = this.item.permissions.find(
              (userPerm) => userPerm.subject === defaultPermission.subject
            );

            if (userPermission) {
              for (const action of defaultPermission.actions) {
                const userAction = userPermission.actions.find(
                  (userAct) => userAct.value === action.value
                );
                action.checked = userAction ? userAction.checked : false;
              }
            }

            this.checkIfAllSelectedBySubject(defaultPermission.subject);
          }
        });
    },
    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      if (!this.$v.item.$error) {
        this.item.permissions = this.permissions;
        this.$store
          .dispatch("User/update", { uuid: this.uuid, data: this.item })
          .then(() => {
            if (this.uuid === this.user.id) {
              this.$ability.update([]);

              const permissionsUser = this.item.permissions.map((item) => {
                const newActions = item.actions
                  .map((action) => (action.checked ? action.value : null))
                  .filter((action) => action !== null);

                return { ...item, actions: newActions };
              });

              this.$ability.update(permissionsUser);
            }
            this.$router.push("/config/users");
            this.notifyDefault("success");
          })
          .catch((err) => {
            this.submited = false;

            if (err.response.data.email) {
              this.notifyDefault("error", "E-mail já cadastrado");
            } else {
              this.notifyDefault("error");
            }
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
};
</script>

<style lang="scss">
.table-permissions {
  strong {
    font-size: 1rem !important;
    font-weight: 500;
  }
}
.table-container {
  overflow-x: auto;
}
.checkbox-permission {
  label {
    font-weight: 400;
  }
}
.list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.custom-checkout-roles-default {
  .custom-control-label {
    font-size: 0.9rem !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
